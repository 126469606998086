<template>
  <div class="login" v-loading="loading">
    <div class="left">
      <img src="../assets/img/login/bl_l.png" alt="">
    </div>
    <div class="right">
      <div class="right_top">
          2 Fast Motors  <br>Regular reminder system
      </div>
      <div class="right_login">
        <div class="right_input">
          <img src="../assets/img/login/user.png" alt="">
          <input type="text" class="inpt_user" v-model="mobile" name="" id="" :placeholder="$t('login_right_input_mobile')">
        </div>
        <div class="right_input_code">
          <div class="inputs">
            <img src="../assets/img/login/code.png" alt="">
            <input type="text" class="inpt_user" v-model="code" name="" id="" :placeholder="$t('login_right_input_code')">
          </div>
          <div class="code_btns" @click="get_code" v-loading="loading_btn">{{ btn_text }}</div>
        </div>
        <div class="right_input">
          <img src="../assets/img/login/passsed.png" alt="">
          <input type="password" class="inpt_password" v-model="password" name="" id="" :placeholder="$t('login_right_input_password')">
        </div>
        <div class="right_input">
          <img src="../assets/img/login/passsed.png" alt="">
          <input type="password" class="inpt_password" v-model="confirm_password" name="" id="" :placeholder="$t('login_right_input_password2')">
        </div>
      </div>
     
      <div class="btns">
        <button class="btns1" @click="login_submit">{{$t('logo_logo_btns2')}}</button>
      </div>
    </div>
    

  </div>
</template>
<script>

export default {
  name: "login",
  components: {},
  data() {
    return {
      loading:false,
      loading_btn:false,
      btn_text:'obtain',
      mobile: "",
      password: "",
      confirm_password:'',
      code:'',
      isSend:true,//可以发送
      timeFun:null,

    };
  },
  computed: {
  },
  watch: {
   

  },
  created() { 
    // localStorage.setItem('token','')

     
    
    localStorage.clear()
    this.btn_text=this.$t('logo_code_btns_text1')

  },
  beforeDestroy(){
    clearInterval(this.timeFun);
  },
  methods: {
    login_submit() {



      if (!this.mobile.trim()) {
        // alertErr("请输入登录账号");
        // alertErr("Please enter your mobile phone number");
        alertErr(this.$t('logo_get_text1'));

        return;
      }

      // var Reg = /^[1][345789][0-9]{9}$/;
      // if(!Reg.test(this.mobile)){
      //   alertErr("Please enter a valid mobile phone number");
      //   return
      // }
      if (!this.code.trim()) {
        // alertErr("请输入验证码");
        // alertErr("Please enter a verification code");
        alertErr(this.$t('logo_get_text2'));

        return;
      }
      if (!this.password.trim()) {
        // alertErr("请输入登录密码");
        // alertErr("Please enter your password");
        alertErr(this.$t('logo_get_text3'));


        return;
      }
      if (!this.confirm_password.trim()) {
        // alertErr("请输入登录密码");
        // alertErr("Please enter your confirmation password");
        alertErr(this.$t('logo_get_text4'));
        return;
      }
      if (this.confirm_password!=this.password) {
        // alertErr("请输入登录密码");
        // alertErr("Passwords are inconsistent");
        alertErr(this.$t('logo_get_text5'));


        return;
      }
      this.loading=true
      this.$api(
        "admin_forget",
        {
          mobile: this.mobile.trim(),
          code: this.code.trim(),
          password: this.password.trim(),
          confirm_password: this.confirm_password.trim(),
        },
        "post"
      ).then((res) => {
        console.log("忘记密码", res);
        if(res.code==200){
          this.$router.replace('/')
          // alertSucc('The modification was successful')
        alertErr(this.$t('logo_get_text6'));

        }else{
          this.loading=false
          alertErr(res.msg)
        }
        
      });
    },
    
    //获取验证码
    get_code(){
      if(!this.isSend){
        return
      }
      if (!this.mobile.trim()) {
        // alertErr("请输入登录账号");
        // alertErr("Please enter your mobile phone number");
        alertErr(this.$t('logo_get_text1'));

        return;
      }
      
      // var Reg = /^[1][345789][0-9]{9}$/;
      // if(!Reg.test(this.mobile)){
      //   alertErr("Please enter a valid mobile phone number");
      //   return
      // }
      this.loading_btn=true

      this.$api("send_code", {
        mobile:this.mobile
      }, "get").then((res) => {
        console.log("获取验证码", res);
        this.loading_btn=false
        this.get_time()
        if (res.code == 200) {
          alertErr(this.$t('logo_get_text7'));

        }else{
          alertErr(res.msg)

        }
      });
    },
    get_time(){
      this.isSend = false;
      let timer = 60;
      this.btn_text = timer + "s";
      this.timeFun = setInterval(() => {
          --timer;
          this.btn_text = timer + "s";
          if (timer == 0) {
              this.isSend = true;
              this.btn_text = this.$t('logo_code_btns_text2');;
              console.log(this.timer)
              clearInterval(this.timeFun);
          }
      }, 1000);

    },
  },
};
</script>
<style scoped lang="less">
.login {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  .left{
    width: 50%;
    // height: 1080px;
    height: 100%;
    // background: url('../assets/img/login/bl_l.png');//
    // position: fixed;
    text-align: center;
    // padding-top: 243px;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .right{
    width: 50%;
    height: 100vh;
    // height: 1080px;
    // background: url('../assets/img/login/bg_r1.png');
    .right_top{
      text-align: center;
      padding-top: 217px;
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: 400;
      font-size: 36px;
      color: #273A8E;
    }
    .right_login{
      padding-top: 110px;
      width: 424px;
      margin: auto;
      .right_input{
        width: 424px;
        height: 50px;
        background: #F7F8FA;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        display: flex;
        align-items: center;
        padding-left: 16px;
        padding-right: 16px;
        margin: auto;
        margin-bottom: 22px;
        .inpt_user{
          padding-left: 20px;
        }
        .inpt_password{
          padding-left: 20px;
        }
        input{
          width: 100%;
        }
      }
      .right_input_code{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 424px;
        height: 50px;
        margin-bottom: 22px;

        .inputs{
          width: 293px;
          height: 50px;
          background: #F7F8FA;
          border-radius: 4px 4px 4px 4px;
          display: flex;
          align-items: center;
          padding-left: 16px;
          padding-right: 16px;
          .inpt_user{
            padding-left: 20px;
          }
          .inpt_password{
            padding-left: 20px;
          }
          input{
            width: 100%;
          }
        }
        .code_btns{
          width: 121px;
          height: 50px;
          background: #273A8E;
          border-radius: 4px 4px 4px 4px;
          line-height: 50px;
          text-align: center;
          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: 400;
          font-size: 14px;
          color: #FFFFFF;
          cursor: pointer;
        }
      }
      
    }
    
    .btns{
      margin-top: 60px;
      text-align: center;
      .btns1{
        width: 424px;
        height: 50px;
        background: linear-gradient( 90deg, #273A8E 0%, #3C54BE 100%);
        border-radius: 27px 27px 27px 27px;
        text-align: center;
        line-height: 50px;
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;

      }
    }


  }
}</style>
